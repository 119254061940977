body {
    /* background-color: #012039; */
    background-color: #221450;
}

.staking__flex-container {
    display: flex;
    justify-content: space-around;
    padding: 0px 2rem;
    align-items: center;
    flex-flow: column;
}

.staking__staked-status-flex {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 700px;
    max-width: 80vw;
    /* align-items: center; */
}

@media screen and (max-width: 700px) {
    .staking__flex-container {
        flex-flow: column;
    }

    .staking__staked-status-flex {
        flex-flow: row;
    }
}

.staking-tool__bg {
    background-color: #221450;
    width: 100vw;
    height: 100vh;
    /* min-height: 100vh; */
    overflow-x: hidden;
}

.staking-tool__project-info-tab {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.staking-tool__project-info {
    padding: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.staking-tool__project-img {
    display: block;
    width: 4rem;
    border: 0px solid white;
    border-radius: 100px;
    max-width: 70vw;
}

.staking-tool__project-text {
    padding-top: 20px;
    color: white;
    text-align: center;
    font-size: 2rem;
}

.staking-tool__connect-btn {
    float: right;
    right: 30px;
    text-align: center;
}

.staking-tool__nfts-staked-progress-bar-bg {
    border: 2px solid #17b361;
    background-color: white;
    height: 30px;
    width: 100%;
    max-width: 80vw;
    /* border-radius: 100px; */
    /* margin-top: 5vh; */
    display: block;
    margin: auto;
    text-align: center;
    font-weight: 900;
    position: relative;
    font-size: 1.4rem;
}

.staking-tool__nfts-staked-progress-bar {
    background-color: #57c58c;
    height: 100%;
    position: absolute;
    /* border-radius: 100px; */
    height: 30px;
}

.staking-tool__nfts-staked-progress-bar-indicator {
    position: absolute;
    width: 100%;
    font-family: 'Share Tech';
    z-index: 2;
    color: black;
    height: 30px !important;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

.staking-tool__nfts-staked-progress-bar-bg span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    color: black;
    text-align: center;
    width: 100%;
}

.staking-tool__purple-box-info {
    display: flex;
    flex-flow: row wrap;
    font-size: 1.2rem;
    color: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.staking-tool__purple-box-info-title {
    font-size: 1.5rem;
    display: block;
    margin: auto;
    text-align: center;
    font-weight: 700;
    color: white;
    width: 100%;
    text-transform: uppercase;
}

.staking-tool__purple-box-info-box {
    display: block;
    margin: auto;
    overflow: hidden;
    text-align: center;
    background-color: rgb(127, 127, 237);
    color: white;
    font-size: 1.2rem;
    cursor: default;
    padding: 0.5rem 1rem;
    margin: 0.3rem 0px;
    width: 100%;
    border: 2px solid rgb(84 84 166);
}

.staking-tool__purple-box-info-box:hover {
    background-color: rgb(84 84 166);
}

@media screen and (max-width: 609px) {
    .staking-tool__purple-box-info {
        font-size: 0.8rem;
    }

    .staking-tool__purple-box-info-title {
        font-size: 1.2rem;
    }
}



.staking-tool__blue-box-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 1.2rem;
    color: white;
    border-radius: 10px;
    height: 100%;
}

.staking-tool__blue-box-info-title {
    font-size: 1.5rem;
}

.staking-tool__red-border-container {
    display: flex;
    justify-content: center;
    padding: 0px;
    border: 3px solid rgb(193, 89, 89);
    width: 100%;
    overflow-y: scroll;
    height: 400px;
    max-height: 50vh;
    backdrop-filter: blur(3px);
}

.staking-tool__red-border-container-align-center {
    align-items: center;
}

.staking-tool__blue-box-info-box {
    display: block;
    overflow: hidden;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    padding: 5px;
    border-radius: 15px;
    background-color: rgb(105, 164, 216);
    border: 7px solid #316287;
    width: calc(100%-10px);
    margin: 0px;
}

.staking-tool__staking-rewards-form-radio {
    display: block;
    overflow: hidden;
    text-align: center;
    color: white;
    font-size: 1.2rem;
    padding: 5px;
    width: fit-content;
    border-radius: 15px;
    overflow: inherit;
    width: 26%;
    margin: 0px;
}

.staking-tool__blue-box-info-box:hover {
    background-color: rgb(88, 133, 199);
    transition: all 0.2s ease-in;
}

.staking-tool__staking-rewards-form-radio input[type='radio'] {
    display: none;
}

.staking-tool__staking-rewards-form-radio button {
    background-color: #6aa4d8;
    cursor: pointer;
    width: -webkit-fill-available;
    transition: all ease-in 0.2s;
}

.staking-tool__staking-rewards-form-radio button:disabled {
    background-color: #316287;
    cursor: default;
}

/* .staking-tool__staking-rewards-form-radio input[type="radio"]:checked+label {
    background-color: #316287;
} */

@media screen and (max-width: 609px) {
    .staking-tool__blue-box-info {
        font-size: 0.8rem;
    }

    .staking-tool__blue-box-info-title {
        font-size: 1.2rem;
    }
}

.staking-tool__stake-unstake-tab-btn {
    color: white !important;
    font-family: 'Share Tech' !important;
    font-size: 1.2rem !important;
    text-decoration: none;
    text-transform: none !important;
    background-color: #c15959 !important;
    margin: 5px 0.1rem 0px 0.1rem !important;
    padding: 0.4rem 0.5rem !important;
}

.Mui-selected {
    background-color: #924141 !important;
}

.staking-tool__stake-unstake-flex-container {
    display: flex;
    padding: 1rem 0.5rem;
    justify-content: center;
    flex-flow: row wrap;
}

.staking-tool__stake-unstake-nft {
    width: 80px;
    height: 80px;
    /* border: 5px solid rgb(100, 78, 225); */
    margin: auto;
    transition: all ease-in 0.2s;
}

.staking-tool__stake-unstake-nft-name {
    padding: 0.4rem 0.5rem;
    color: rgb(0, 178, 255);
    font-weight: 600;
    border-width: 0px 5px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: initial;
    border-left-style: solid;
    border-top-color: rgb(100, 78, 225);
    border-right-color: rgb(100, 78, 225);
    border-bottom-color: initial;
    border-left-color: rgb(100, 78, 225);
    border-image: initial;
    font-size: 0.7rem;
    background: rgba(79, 77, 179, 0.72);
}

.staking-tool__staking-nft {
    width: 50px;
    height: 50px;
    border: 2px solid rgb(100, 78, 225);
    margin: auto;
    transition: all ease-in 0.2s;
}

.staking-tool__staking-nft:hover {
    opacity: 85%;
}

.staking-tool__stake-unstake-nft:hover {
    opacity: 85%;
    cursor: pointer;
}

.staking-tool__stake-unstake-nft-input-checkbox {
    display: none;
}

.staking-tool__stake-unstake-nft-label {
    display: block;
    position: relative;
    margin: 2px;
    cursor: pointer;
}

.staking-tool__stake-unstake-nft-label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 10px;
    height: 10px;
    font-size: 0.4rem;
    text-align: center;
    transition-duration: 0.4s;
    transform: scale(0);
}


:checked+.staking-tool__stake-unstake-nft-label {
    border-color: #ddd;
}

:checked+.staking-tool__stake-unstake-nft-label:before {
    content: "✓";
    background-color: white;
    color: rgb(90, 208, 69);
    font-weight: 800;
    transition: all ease-in 0.2s;
    transform: scale(1.3);
    border: 2px solid rgb(54, 217, 25);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.staking-tool__stake-unstake-nft-label img {
    transition: all ease-in 0.2s;
}

:checked+.staking-tool__stake-unstake-nft-label img {
    transform: scale(0.9);
    z-index: -1;
}

.staking-tool_stake-unstake-form-submit-btn {
    display: block;
    margin: auto;
    color: white;
    font-family: 'Share Tech';
    font-size: 1.6rem;
    background-color: #5788d2;
    padding: 0.3rem 1rem;
    text-transform: none;
    line-height: normal;
    cursor: pointer;
    border: 1px solid #2f5792;
}

.staking-tool_stake-unstake-form-submit-btn:hover {
    background-color: #2f5792;
}

.staking-tool_stake-unstake-form-submit-btn:disabled {
    opacity: 85%;
    background-color: #3c629b;
    cursor: default;
}

#staking-tool__stake-nft-options-interval {
    padding-left: 1rem !important;
    padding: 0.5rem;
    font-family: 'Share Tech';
    padding-right: 32px !important;
}

#staking-tool__stake-nft-options-reward-currency {
    padding-left: 1rem !important;
    padding: 0.5rem;
    font-family: 'Share Tech';
    padding-right: 32px !important;
}

.staking-tool__stake-nft-menu-item {
    background-color: rgb(110, 110, 206) !important;
    font-family: 'Share Tech' !important;
}

ul {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.staking-tool__locked-unlocked-radio-btn span {
    font-size: 1rem;
    font-family: 'Share Tech';
    color: white;
    text-transform: uppercase;
}

.staking-tool__stake-unstake-locked-nft {
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
}

.staking-tool__stake-unstake-locked-nft:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
}

.staking-tool__stake-unstake-locked-nft img {
    transition: all ease-in 0.2s;
}

/* Timer  */
.staking-tool__reward-time-timer {
    display: flex;
    background-color: rgb(127, 127, 237);
    flex-flow: row wrap;
    justify-content: center;
    font-size: 0.6rem;
    color: white;
    /* font-family: 'Robot 9000' */
}

.staking-tool__reward-time-timer-columns {
    margin-left: 4px;
    margin-right: 4px;
}

.staking-tool__claim-btns-container {
    border: 2px solid #fff;
    display: flex;
    width: fit-content;
    margin: auto;
}

.staking-tool__claim-btn {
    border: 1px solid #20203d;
    height: 100%;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem;
    font-family: 'Share Tech';
    color: white;
    background: rgb(44, 44, 79);
}

.staking-tool__balance-bar {
    padding: 0px 0.8rem;
    height: 100%;
    cursor: default;
    text-align: center;
    background: rgb(44, 44, 79);
    display: flex;
    font-family: 'Share Tech';
    align-items: center;
    justify-content: center;
    color: white;
}

.staking-tool__balance-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

#staking-tool__tokens-select-menu {
    padding-left: 1rem !important;
    padding: 0px;
    padding-right: 32px !important;
}

.staking-tool__token-select-option {
    background-color: rgb(126 108 255) !important
}


.staking-tool__claim-btn:hover {
    background-color: #20203d;
    cursor: pointer;
}

.staking-tool__claim-btn:disabled {
    background-color: #20203d;
    cursor: default;
}